import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";

const PriceListModal = ({ show, onClose }) => {
  const [loading, setLoading] = useState(false);

  const fetchAndExportToMain = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://omega.deltaservis.com.tr/_api/queue/api.php?opt=salesStockPrices"
      );
      const products = response.data;

      const flattenedData = products.flatMap((brand) =>
        brand.top_models.flatMap((topModel) =>
          topModel.models.flatMap((model) =>
            model.grades.flatMap((grade) =>
              grade.variants.map((variant) => ({
                VariantID: variant.variant_id,
                Marka: brand.brand_title,
                Model: topModel.top_model,
                Hafıza: grade.memories.new_memory,
                Kalite: grade.grade_title,
                Renk: variant.color,
                Fiyat: grade.price,
              }))
            )
          )
        )
      );

      const worksheet = XLSX.utils.json_to_sheet(flattenedData);

      worksheet["!cols"] = [
        { width: 15 },
        { width: 30 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      const today = new Date();
      const dateString = today.toISOString().split("T")[0];
      XLSX.writeFile(workbook, `Yenilenmiş Ürün Fiyatları ${dateString}.xlsx`);
    } catch (error) {
      console.error("Liste indirilirken hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };
  const mapping = {
    "Müdahale Var": null,
    "Çizgi/Leke": "Çizgi/Piksel Problemi",
    "Cam D Grade": "Cam Çizik",
    "Bazı fonksiyonlar çalışmıyor": null,
    "Bayi Özel": "Kasa Kozmetiği",
    "ID Pasif": "ID Pasif",
    "2 No Leke": "Kamera Lekeli",
    "3 No Leke": "Kamera Lekeli",
    "4 No Leke": "Kamera Lekeli",
    "Batarya bilinmiyor": "Batarya Bilinmiyor",
    "Ekran bilinmiyor": "Ekran Bilinmiyor",
    "Kamera bilinmiyor": "Kamera Bilinmiyor",
    "2 No Gölge": "2 No Gölge",
    Çatlak: "ID Okuyucu Çatlak",
    "3 No Gölge": "3 No Gölge",
    "4 No Gölge": "4 No Gölge",
    "BL Lekeli": "BL Lekeli",
    "TT Pasif": "TrueTone Pasif",
  };
  const transformOutletAnswers = (answers) => {
    if (!answers || typeof answers !== "string") {
      return [];
    }

    return answers
      .split(";")
      .map((item) => item.trim())
      .map((answer) => mapping[answer] ?? null)
      .filter((answer) => answer !== null);
  };

  const fetchAndExportToOutlet = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://omega.deltaservis.com.tr/_api/queue/api.php?opt=salesStockPricesOutlet"
      );
      const responseData = response.data;
      const gradeMapping = {
        "Delta Platinum": "Mükemmel",
        "Beta Gold": "Çok İyi",
        "Alfa Silver": "İyi",
        "Bayi Özel": "Bayi Özel",
      };
      const flattenedData = Object.keys(responseData).flatMap((brandKey) => {
        const brand = responseData[brandKey];

        return brand.forms.map((form) => {
          const transformedAnswers = transformOutletAnswers(
            form.outlet_answers
          );

          return {
            ID: form.form_id,
            Marka: brandKey,
            Model: form.variant_title,
            Kalite: gradeMapping[form.grade_title],
            Hafıza: form.memory,
            Renk: form.color,
            Outlet: transformedAnswers.join(" , "),
            Fiyat: form.price.split(",")[0],
            Video: form.video_url,
          };
        });
      });

      const worksheet = XLSX.utils.json_to_sheet(flattenedData);
      console.log(worksheet);
      worksheet["!cols"] = [
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 30 },
        { width: 15 },
        { width: 20 },
        { width: 30 },
      ];
      flattenedData.forEach((data, rowIndex) => {
        if (data.Video) {
          const cellAddress = XLSX.utils.encode_cell({
            r: rowIndex + 1,
            c: 8,
          });
          worksheet[cellAddress] = {
            t: "s",
            v: "Video",
            l: { Target: data.Video },
          };
        }
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      const today = new Date();
      const dateString = today.toISOString().split("T")[0];
      XLSX.writeFile(workbook, `Outlet Ürün Fiyatları ${dateString}.xlsx`);
    } catch (error) {
      console.error("Listeyi İndir 2 için hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70">
      <div className="relative bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white rounded-lg w-[90%] max-w-4xl p-6">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white text-xl font-bold hover:text-red-500"
        >
          ✕
        </button>

        <div className="flex flex-col md:flex-row items-stretch space-y-6 md:space-y-0 md:space-x-6">
          {/* Sol Bölüm */}
          <div className="flex-1 flex flex-col items-center justify-between bg-gradient-to-b from-gray-700 to-gray-900 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4">YENİLENMİŞ ÜRÜNLER</h2>
            <p className="text-gray-300 text-center mb-4">
              Delta Servis güvencesiyle yenilenmiş cep telefonlarına sahip olun!
              Avantajlı fiyatlar ve 12 ay taksit imkanıyla kazancınızı artırın.
            </p>
            <button
              onClick={fetchAndExportToMain}
              className="rounded-lg bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-lg hover:bg-red-700"
              disabled={loading}
            >
              {loading ? "İndiriliyor..." : "Listeyi İndir"}
            </button>
          </div>

          {/* Sağ Bölüm */}
          <div className="flex-1 flex flex-col items-center justify-between bg-gradient-to-b from-gray-700 to-gray-900 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4">OUTLET ÜRÜNLER</h2>
            <p className="text-gray-300 text-center mb-4">
              Outlet ürünlerimizde yüksek kaliteye uygun fiyatlarla sahip olun.
              Her ürünün özel videosunu izleyerek satın almadan önce tüm
              detayları keşfedin!
            </p>
            <button
              onClick={fetchAndExportToOutlet}
              className="rounded-lg bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-lg hover:bg-red-700"
              disabled={loading}
            >
              {loading ? "İndiriliyor..." : "Listeyi İndir"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceListModal;
